import React from 'react';
import { ClickAwayListener, Fade, Popper } from '@mui/material';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import dynamic from 'next/dynamic';
import Cookies from '@ictlife/core/config/cookies';
import AppAvatar from '../../../utils/AppAvatar';
import BusinessListSkeleton from './BusinessListSkeleton';

const UserBusinessList = dynamic(() => import('./UserBusinessList'), {
  loading: () => <BusinessListSkeleton showAdd />,
});
const ProfilePopper = ({
  anchor,
  setOpenReportDialog,
  setOpenProfile,
  // setOpenThemePopper,
}) => {
  const router = useRouter();
  const { b_username } = router.query;
  const dispatch = useDispatch();
  const sourcePackage = process.env.NEXT_PUBLIC_SOURCE_PACKAGE;

  const appThemeState = useSelector(store => store.appThemeState);
  const { theme } = appThemeState;

  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      profile_photo: profilePhoto,
      merchant: { business_username, business_name, user_id: merchantUserId },
    },
  } = merchantProfileState;

  const userInfoState = useSelector(store => store.userInfoState);
  const {
    user: { id: userId },
  } = userInfoState;

  const isBusinessOwner = merchantUserId === userId;

  const deleteSession = () => {
    const currentSession = jwt_decode(Cookies.get('ictl_token'));
    const session = {
      id: currentSession.session_id,
    };
    import('../../../state/actions/authenticationAction').then(
      authenticationAction => {
        authenticationAction.logoutUser(dispatch, session);
      }
    );
    setOpenProfile({ open: false, anchor: null });
  };

  return (
    <ClickAwayListener
      onClickAway={() => setOpenProfile({ open: false, anchor: null })}
    >
      <Popper
        open
        anchorEl={anchor}
        style={{ zIndex: '20' }}
        transition
        className={theme ?? ''}
        placement="bottom-end"
        modifiers={{
          arrow: {
            enabled: true,
            element: anchor,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className="topnav-popper logout-popper">
              {b_username && merchantProfileState.errorCode !== 404 && (
                <>
                  <div className="business-setting">
                    {business_name &&
                      (sourcePackage === 'marketplace' || isBusinessOwner) && (
                        <>
                          <div className="main-pop-avatar">
                            <AppAvatar
                              src={profilePhoto?.url}
                              alt={business_name}
                            />
                          </div>

                          <div className="identity">
                            <div className="name">{business_name}</div>
                            <div className="username">@{business_username}</div>
                          </div>
                          {isBusinessOwner && sourcePackage === 'merchant' ? (
                            <div>
                              <button
                                className="custom-button default outlined sm lc"
                                onClick={() =>
                                  router.push(
                                    `/business/${business_username}/profile/logo`
                                  )
                                }
                              >
                                Manage Business Account
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                className="custom-button default outlined sm lc"
                                onClick={() => {
                                  import(
                                    '../../../state/actions/flagsAction'
                                  ).then(flagsAction => {
                                    flagsAction.getReportReasons(
                                      dispatch,
                                      'merchants'
                                    );
                                  });
                                  setOpenReportDialog(true);
                                }}
                              >
                                Report business
                              </button>
                            </div>
                          )}
                        </>
                      )}
                  </div>
                  <hr />
                </>
              )}
              {sourcePackage === 'merchant' && (
                <UserBusinessList setOpenProfile={setOpenProfile} />
              )}
              {(sourcePackage === 'marketplace' || !b_username) && (
                <>
                  <div
                    className="link"
                    onClick={() =>
                      router.push(
                        b_username
                          ? `/business/${b_username}/settings/sessions`
                          : '/settings/sessions'
                      )
                    }
                  >
                    <span className="material-icons icon">&#xe1b1;</span>
                    <span>Logged In devices</span>
                  </div>
                  <hr />
                  <div
                    className="link"
                    onClick={() =>
                      router.push(
                        b_username
                          ? `/business/${b_username}/settings/password`
                          : '/settings/password'
                      )
                    }
                  >
                    <span className="material-icons icon">&#xe73c;</span>
                    <span>Manage Password</span>
                  </div>
                  <hr />
                  <div
                    className="link"
                    onClick={() =>
                      router.push(
                        b_username
                          ? `/business/${b_username}/settings/profile`
                          : sourcePackage === 'marketplace'
                          ? `/user/${userId}`
                          : '/settings/profile'
                      )
                    }
                  >
                    <span className="material-icons icon">&#xf02e;</span>
                    <span>Personal Profile</span>
                  </div>
                </>
              )}
              <hr />
              {/* <div
                className="link"
                onClick={e => {
                  const anchor = e.currentTarget;
                  setOpenThemePopper(openThemePopper => ({
                    open: !openThemePopper.open,
                    anchor,
                  }));
                }}
              >
                {theme === 'dark' ? (
                  <span className="material-icons icon">&#xe518;</span>
                ) : (
                  <span className="material-icons icon">&#xe51c;</span>
                )}
                <span>Switch Theme</span>
              </div> */}

              {/* <hr /> */}
              <div className="popup-footer">
                <button
                  className="custom-button default outlined sm lc"
                  onClick={deleteSession}
                >
                  Sign out
                </button>
              </div>
            </div>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default ProfilePopper;
