import { useRouter } from 'next/router';
import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Badge, SwipeableDrawer, IconButton } from '@mui/material';
import Cookies from '@ictlife/core/config/cookies';
import useMounted from '@ictlife/core/hooks/useMounted';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useNotifications } from '@ictlife/core/state/actions/notificationsAction';
import Filters from '@ictlife/marketplace/components/categories/SuperCategoriesLayout/Filters';
import Search from '@ictlife/marketplace/components/landing/Search';
import Link from 'next/link';
import BusinessSideNav from '@ictlife/marketplace/components/business/BusinessSideNav';
import s from '../styles/topbar/MobileNav.module.scss';
import AppAvatar from '../../../utils/AppAvatar';
import ProfilePopper from './ProfilePopper';
import SuperCategorySelect from './SuperCategorySelect';

const AuthenticationLinks = dynamic(() =>
  import('./Links').then(link => link.AuthenticationLinks)
);
const BrandLinks = dynamic(() =>
  import('./Links').then(link => link.BrandLinks)
);
const NotificationsPopper = dynamic(() => import('./NotificationsPopper'));
const GoLiveDialog = dynamic(() => import('./GoLiveDialog'));

const MobileNav = ({
  setOpenReportDialog,
  profile_completeness_value,
  page,
}) => {
  const router = useRouter();
  const { b_username } = router.query;
  const sourcePackage = process.env.NEXT_PUBLIC_SOURCE_PACKAGE;

  const [switchBarOpen, setSwitchBarOpen] = useState(false);
  const [openMobileNotifications, setOpenMobileNotifications] = useState({
    open: false,
    anchor: null,
  });
  const [openMobileProfile, setOpenMobileProfile] = useState({
    open: false,
    anchor: null,
  });
  const [openLiveDialog, setOpenLiveDialog] = useState(false);

  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      profile_photo: profilePhoto,
      merchant: {
        id: merchantId,
        business_username,
        business_name,
        status: liveStatus,
        user_id: merchantUserId,
        account_status,
      },
    },
    viewMode,
  } = merchantProfileState;

  const userInfoState = useSelector(store => store.userInfoState);
  const {
    profile_photo,
    user: { id: userId, first_name, last_name },
  } = userInfoState;

  const { notifications: appNotifications } = useNotifications({
    userId,
    merchantId:
      process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'merchant' &&
      userId === merchantUserId
        ? merchantId
        : undefined,
  });

  const isBusinessOwner =
    merchantUserId === userId && sourcePackage === 'merchant';

  const isLoggedIn = Cookies.get('ictl_token');
  const isMounted = useMounted();

  const appThemeState = useSelector(store => store.appThemeState);
  const { theme } = appThemeState;

  const showMenuDot =
    (business_username && liveStatus === 'inactive' && page !== 'profile') ||
    router.query.selectedCategory ||
    router.query.search;

  const SETTINGS_LINKS = [
    {
      title: 'Sessions',
      href: `/business/${business_username}/settings/sessions`,
    },
    {
      title: 'Change Password',
      href: `/business/${business_username}/settings/password`,
    },
    {
      title: 'Personal Profile',
      href: `/business/${business_username}/settings/profile`,
    },
  ];

  if (isBusinessOwner && !viewMode) {
    SETTINGS_LINKS.unshift({
      title: 'Business Profile',
      href: `/business/${business_username}/settings/business-settings`,
    });
  }

  const username_route = b_username && account_status === 'claimed';

  const currentMerchant =
    typeof window !== 'undefined' &&
    sourcePackage === 'merchant' &&
    localStorage.activeMerchant &&
    JSON.parse(localStorage.activeMerchant);

  return (
    <div className="mobile-top-nav">
      <div className="left-section">
        <IconButton
          onClick={() => setSwitchBarOpen(open => !open)}
          size="large"
        >
          <Badge color="primary" variant="dot" invisible={!showMenuDot}>
            {process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'merchant' ? (
              <span className="material-icons">&#xe5d2;</span>
            ) : (
              <span className="material-icons">&#xef4f;</span>
            )}
          </Badge>
        </IconButton>
      </div>
      <div>
        {business_name ? (
          <Link
            href={{
              pathname: '/business/[b_username]',
              query: {
                b_username: business_username,
              },
            }}
          >
            <a
              className="text-gray-600 visited:text-gray-600 line-clamp-1"
              title={business_name}
            >
              {business_name}
            </a>
          </Link>
        ) : process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'merchant' ? (
          currentMerchant &&
          currentMerchant?.merchant.user_id === userId &&
          !username_route && (
            <button
              className="custom-button outlined default lc"
              onClick={() =>
                router.push(
                  `/business/${currentMerchant.merchant.business_username}`
                )
              }
            >
              {currentMerchant.merchant.business_username} dashboard
            </button>
          )
        ) : (
          <SuperCategorySelect />
        )}
      </div>
      <div className="right-section">
        {isLoggedIn && isMounted ? (
          <>
            {(isBusinessOwner && b_username) ||
            (sourcePackage === 'marketplace' && userId) ? (
              <div
                className="mid-section"
                onClick={e => {
                  const anchor = e.currentTarget;
                  setOpenMobileNotifications(open => ({
                    anchor,
                    open: !open.open,
                  }));
                }}
              >
                <Badge
                  badgeContent={appNotifications?.unread_count || 0}
                  color="primary"
                >
                  <span
                    className={cn('material-icons', 'notification-icon', {
                      'open-notifications': openMobileNotifications.open,
                    })}
                  >
                    &#xe7f4;
                  </span>
                </Badge>
              </div>
            ) : (
              <div />
            )}
            <div
              className="profile-section"
              onClick={e => {
                const anchor = e.currentTarget;
                setOpenMobileProfile(open => ({
                  open: !open.open,
                  anchor,
                }));
              }}
            >
              <div className="user-avatar">
                {sourcePackage === 'merchant' &&
                b_username &&
                isBusinessOwner ? (
                  <div className="topbar-avatar">
                    <AppAvatar
                      src={
                        profilePhoto?.thumbnail_url ||
                        (!business_name && profile_photo)
                      }
                      alt={business_name || `${first_name} ${last_name}`}
                      priority
                    />
                  </div>
                ) : (
                  <div className="topbar-avatar">
                    <AppAvatar
                      src={profile_photo}
                      alt={`${first_name} ${last_name}`}
                      priority
                    />
                  </div>
                )}
              </div>
              {/* <div className="profile-details">
                <span className="role">
                  {isBusinessOwner && b_username ? 'Owner' : ''}
                </span>
                <span className="name">
                  {b_username && isBusinessOwner
                    ? business_name || `${first_name} ${last_name}`
                    : `${first_name} ${last_name}`}
                </span>
              </div>
              <div className="chevron-down">
                <span className="material-icons">&#xe5cf;</span>
              </div> */}
            </div>
          </>
        ) : (
          <AuthenticationLinks />
        )}
      </div>
      <SwipeableDrawer
        anchor="left"
        open={switchBarOpen}
        disableBackdropTransition
        disableDiscovery
        onClose={() => setSwitchBarOpen(false)}
        onOpen={() => setSwitchBarOpen(true)}
        className={cn('override', theme)}
      >
        <div className={cn(s.drawerWrapper, 'p-4')}>
          {b_username ? (
            page === 'settings' ? (
              <div className={s.drawerHolder}>
                <div className={s.drawerBrand}>
                  <div className={s.sectionAvatar}>
                    <AppAvatar
                      src={profile_photo}
                      alt={`${first_name} ${last_name}`}
                    />
                  </div>
                  <span
                    className={s.ictlife}
                  >{`${first_name} ${last_name}`}</span>
                </div>
                <hr />
                {SETTINGS_LINKS.map(link => (
                  <Fragment key={link.href}>
                    <div
                      className={cn(s.tag, s.link)}
                      onClick={() => {
                        setSwitchBarOpen(false);
                        router.push(link.href, undefined, { shallow: true });
                      }}
                    >
                      <span>{link.title}</span>
                      {link.href === router.asPath && (
                        <span className={cn('material-icons', s.icon)}>
                          &#xe86c;
                        </span>
                      )}
                    </div>
                    <hr />
                  </Fragment>
                ))}
              </div>
            ) : (
              <BusinessSideNav closeSideBar={() => setSwitchBarOpen(false)} />
            )
          ) : (
            <div className={s.drawerHolder}>
              <div>
                {process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'marketplace' ? (
                  <div className="space-y-4">
                    <div className="sticky top-0 bg-white z-10">
                      <div className="flex items-center justify-between border-b py-2 px-4">
                        <SuperCategorySelect />
                        <div>
                          <IconButton onClick={() => setSwitchBarOpen(false)}>
                            <span className="material-icons">&#xe5cd;</span>
                          </IconButton>
                        </div>
                      </div>
                      <div className="p-2 border rounded-2xl m-2">
                        <Search
                          onSearch={() => {
                            setSwitchBarOpen(false);
                          }}
                        />
                      </div>
                    </div>

                    <Filters />
                  </div>
                ) : (
                  <div className="p-4">
                    <BrandLinks />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </SwipeableDrawer>
      {openMobileNotifications.open && (
        <NotificationsPopper
          anchor={openMobileNotifications.anchor}
          setOpenNotifications={setOpenMobileNotifications}
        />
      )}
      {openMobileProfile.open && (
        <ProfilePopper
          anchor={openMobileProfile.anchor}
          setOpenReportDialog={setOpenReportDialog}
          setOpenProfile={setOpenMobileProfile}
        />
      )}
      {openLiveDialog && sourcePackage === 'merchant' && (
        <GoLiveDialog
          setOpenLiveDialog={setOpenLiveDialog}
          profile_completeness_value={profile_completeness_value}
        />
      )}
    </div>
  );
};
export default MobileNav;
