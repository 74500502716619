import React, { Fragment } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useRouter } from 'next/router';

const BusinessListSkeleton = ({ showAdd }) => {
  const router = useRouter();
  return [1, 2, 3].map(business => (
    <Fragment key={business}>
      <div className="business">
        <div className="logo">
          <div className="business-avatar">
            <Skeleton variant="circular" width={45} height={45} />
          </div>
        </div>
        <div className="name">
          <div className="b-name">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="8em"
              height="0.7em"
            />
          </div>
          <div className="b-username">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="6em"
              height="0.6em"
            />
          </div>
        </div>
      </div>
      <div className="separator">
        <div></div>
        <hr />
      </div>

      {showAdd && (
        <div
          className="business"
          onClick={() => {
            router.push('/business/register');
          }}
        >
          <div className="logo">
            <img
              src="/images/icons/plus.svg"
              alt="Add a new business"
              width="25"
            />
          </div>
          <div className="name">
            <div className="b-name">Add a new business</div>
            <div className="b-username">
              Register a new business under your current account
            </div>
          </div>
        </div>
      )}
    </Fragment>
  ));
};
export default BusinessListSkeleton;
